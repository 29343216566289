import { Either, Maybe } from 'monet';

/**
 * Запрос на получения клиник
 *
 * @param page
 * @param clinicQuery
 * @param sortType
 * @param sortDirection
 * @constructor
 */
export function GetClinicsWithAccounts(
  page = 1,
  clinicQuery = '',
  sortType = '',
  sortDirection = '',
) {
  this.page = page;
  this.match = clinicQuery;
  this.sortType = sortType;
  this.sortDirection = sortDirection;

  this.toQuery = () => ({
    page: this.page,
    match: this.match,
    sort_type: this.sortType,
    sort_direction: this.sortDirection,
  });
}

/**
 * @description Шлюз интеграции Medflex.
 * @param {Axios} axios HTTP-клиент.
 * @constructor
 */
export default function MedflexGateway(axios) {
  /**
   * @description Получить клиники-кандидаты.
   * @param {number} page  Номер страницы.
   * @param {string} match Совпадение.
   * @returns {Promise<Maybe>}
   */
  this.getCandidateClinics = (page, match) => axios
    .get('/api/admin/medflex-candidate-clinics', { params: { match, page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить клиники подключенной интеграцией.
   * @param {GetClinicsWithAccounts} request  реквест.
   * @returns {Promise<Maybe>}
   */
  this.getClinicsWithAccount = (request) => axios
    .get('/api/admin/medflex/accounts', { params: request.toQuery() })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить организации.
   * @param {number} page
   * @returns {Promise<Maybe>}
   */
  this.getOrganizations = (page) => axios
    .get('/api/admin/medflex-organizations', { params: { page } })
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Добавить аккаунт.
   * @param {int} candidateId
   * @param {string} departmentOid
   * @param {string} organizationOid
   * @param {string} departmentName
   * @param {string} lpuUid
   * @returns {Promise<Either>}
   */
  this.addAccount = (
    candidateId,
    departmentOid,
    organizationOid,
    departmentName,
    lpuUid,
  ) => axios
    .post(
      `/api/admin/clinics/${candidateId}/medflex/accounts`,
      {
        department_oid: departmentOid,
        organization_oid: organizationOid,
        department_name: departmentName,
        lpu_uid: lpuUid,
      },
    )
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );

  /**
   * @description Удалить интеграцию клиники.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.removeIntegration = (clinicId) => axios
    .delete(`/api/admin/clinics/${clinicId}/medflex/remove`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Получить историю отправок документов клиники.
   * @param clinicId
   * @returns {Promise<Maybe<unknown>>}
   */
  this.getClinicHistorySendings = (clinicId) => axios
    .get(`/api/admin/clinics/${clinicId}/medflex/documents`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Отключить интеграцию клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.disableIntegration = (clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/medflex/disable`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Включить интеграцию клинике.
   * @param {number} clinicId Идентификатор клиники.
   * @returns {Promise<Either>}
   */
  this.enableIntegration = (clinicId) => axios
    .put(`/api/admin/clinics/${clinicId}/medflex/enable`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Показать страницу ошибок ЕГИСЗ по клинике.
   * @param {string} clinicId   Идентификатор клиники.
   * @param {number} pageNumber Номер страницы.
   * @returns {Promise<Maybe>}
   */
  this.viewEgiszErrorsPageForClinic = (clinicId, pageNumber) => axios
    .get(`/api/admin/clinics/${clinicId}/egisz-errors-pages/${pageNumber}`)
    .then(async (response) => Maybe.fromEmpty(response.data));

  /**
   * @description Обновить список организаций.
   * @returns {Promise<Maybe>}
   */
  this.updateOrganizationList = () => axios
    .post('/api/admin/medflex-organizations/update')
    .then(
      async (response) => Either.Right(response.data),
      async (error) => {
        if (error.isAxiosError && error.response.status === 400) {
          return Either.Left(error.response.data);
        }
        throw error;
      },
    );
}
